import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import Intro from './pages/Intro/Intro'
import About from './pages/About/About'
import Menu from './pages/Menu/Menu'
import CukrarenPonuka from './pages/CukrarenPonuka/CukrarenPonuka'
import Gallery from './pages/Gallery/Gallery'
import Cursor from './components/Cursor/cursor'

const App = () => {
    useEffect(() => {
        function cursor_hover() {
            const cursor_dot = document.querySelector('.innerCursor')

            cursor_dot.classList.add('innerCursorHover')
        }
        function cursor_stop_hover() {
            const cursor_dot = document.querySelector('.innerCursor')

            cursor_dot.classList.remove('innerCursorHover')
        }

        function nastavenie_hover_na_elementy() {
            const vsetky_hover_elementy = document.querySelectorAll('[data-hover="hover"]')

            vsetky_hover_elementy.forEach((element) => {
                element.onmouseover = cursor_hover
                element.onmouseout = cursor_stop_hover
            })
        }

        nastavenie_hover_na_elementy()
    }, [])

    return (
        <>
            <Routes>
                <Route path='/' element={<Intro />} />
                <Route path='/o-nas' element={<About />} />
                <Route path='/ponuka' element={<Menu />} />
                <Route path='/cukraren-ponuka' element={<CukrarenPonuka />} />
                <Route path='/galeria' element={<Gallery />} />
            </Routes>
            <Cursor />
        </>
    )
}

export default App
