import React from 'react'
import './BranchOffices.css'
import { Link } from 'react-router-dom'
import arrowIcon from '../../Images/arrowIcon.png'
import arrowSecondIcon from '../../Images/arrowIcontwo.png'
import sweet from '../../Images/sweet.png'
import croissant from '../../Images/croissant.png'

const BranchOffices = () => {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <div className='BranchOffices' id='offices'>
            <div className='allOffices'>
                <div className='oneOffice'>
                    <img className='officeImage' src={arrowIcon} alt='' />
                    <div className='officuContent'>
                        <div className='headline'>
                            
                            <h2>MARIANNA NOVA cukráreň</h2>
                        </div>
                        <p>
                            Námestie Generála Štefánika 3, <br /> cukráreň
                        </p>
                        <div className='hours'>
                            <p>
                                <span>pondelok</span> 8,00 - 18,00 hod
                            </p>
                            <p>
                                <span>utorok</span> 8,00 - 18,00 hod
                            </p>
                            <p>
                                <span>streda</span> 8,00 - 18,00 hod
                            </p>
                            <p>
                                <span>štvrtok</span> 8,00 - 18,00 hod
                            </p>
                            <p>
                                <span>piatok</span> 8,00 - 18,00 hod
                            </p>
                            <p>
                                <span>sobota</span> 10,00 - 18,00 hod
                            </p>
                            <p>
                                <span>nedeľa</span> 11,00 - 19,00 hod
                            </p>
                            <p>
                                <span>tel. číslo</span>052/23 883 38
                            </p>
                        </div>
                        <Link to='/ponuka' onClick={scrollToTop}>
                            <button data-hover='hover'>
                                <p>PONUKA</p>
                                <svg xmlns='http://www.w3.org/2000/svg' width='19' height='18' viewBox='0 0 19 18' fill='none'>
                                    <path
                                        d='M16.4831 1.77182C16.3571 0.953023 15.5912 0.391363 14.7724 0.517317L1.42939 2.56985C0.610593 2.6958 0.0489336 3.46167 0.174887 4.28047C0.300841 5.09927 1.06671 5.66093 1.88551 5.53497L13.746 3.7105L15.5705 15.571C15.6964 16.3898 16.4623 16.9514 17.2811 16.8255C18.0999 16.6995 18.6616 15.9337 18.5356 15.1149L16.4831 1.77182ZM5.20959 17.8871L16.2101 2.88695L13.7909 1.11281L2.79041 16.1129L5.20959 17.8871Z'
                                        fill='#432E2E'
                                    />
                                </svg>
                            </button>
                        </Link>
                    </div>
                    <img className='decorationImage' src={sweet} alt='' />
                </div>
                <div className='line'></div>
                <div className='oneOffice'>
                    <img className='officeImage' src={arrowSecondIcon} alt='' />
                    <div className='officuContent'>
                        <div className='headline'>
                            <h2>MARIANNA NOVA pekáreň</h2>
                        </div>
                        <p>
                            Námestie Generála Štefánika 3,
                            <br />
                            cukráreň
                        </p>
                        <div className='hours'>
                            <p>
                                <span>pondelok</span> 7,00 - 17,30 hod
                            </p>
                            <p>
                                <span>utorok</span> 7,00 - 17,30 hod
                            </p>
                            <p>
                                <span>streda</span> 7,00 - 17,30 hod
                            </p>
                            <p>
                                <span>štvrtok</span> 7,00 - 17,30 hod
                            </p>
                            <p>
                                <span>piatok</span> 7,00 - 17,30 hod
                            </p>
                            <p>
                                <span>tel. číslo </span>052/20 101 20
                            </p>
                        </div>
                        <Link to='/cukraren-ponuka' onClick={scrollToTop}>
                            <button data-hover='hover'>
                                <p>PONUKA</p>
                                <svg xmlns='http://www.w3.org/2000/svg' width='19' height='18' viewBox='0 0 19 18' fill='none'>
                                    <path
                                        d='M16.4831 1.77182C16.3571 0.953023 15.5912 0.391363 14.7724 0.517317L1.42939 2.56985C0.610593 2.6958 0.0489336 3.46167 0.174887 4.28047C0.300841 5.09927 1.06671 5.66093 1.88551 5.53497L13.746 3.7105L15.5705 15.571C15.6964 16.3898 16.4623 16.9514 17.2811 16.8255C18.0999 16.6995 18.6616 15.9337 18.5356 15.1149L16.4831 1.77182ZM5.20959 17.8871L16.2101 2.88695L13.7909 1.11281L2.79041 16.1129L5.20959 17.8871Z'
                                        fill='#432E2E'
                                    />
                                </svg>
                            </button>
                        </Link>
                    </div>
                    <img className='decorationImage' src={croissant} alt='' />
                </div>
            </div>
        </div>
    )
}

export default BranchOffices
