import './Footer.css'

import FooterLogo from '../../Images/footerLogo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer id='contact'>
            <div class='grid-container'>
                <div class='grid-item item1'>
                    <div className='divInsideItem'>
                        <div className='buttonsFooter'>
                            <Link to='https://www.facebook.com/cukrarenpekarenmariannanova' target='_blank'>
                                <button className='offerBtn' data-hover='hover'>
                                    <span className='svgSpan'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='13'
                                            height='14'
                                            viewBox='0 0 13 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M2.01232 12.1603C2.09872 12.7057 2.61096 13.0779 3.15644 12.9915L12.0456 11.5836C12.5911 11.4972 12.9633 10.985 12.8769 10.4395C12.7905 9.89399 12.2783 9.52183 11.7328 9.60822L3.83126 10.8597L2.57979 2.95819C2.49339 2.41271 1.98115 2.04054 1.43566 2.12694C0.890178 2.21333 0.518013 2.72557 0.604409 3.27106L2.01232 12.1603ZM10.1857 0.412215L2.19099 11.416L3.80902 12.5916L11.8038 1.58779L10.1857 0.412215Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </span>
                                    <span>FACEBOOK</span>
                                </button>
                            </Link>
                        </div>
                        <h1>
                            @Cukráreň a Pekáreň <br />
                            Marianna NOVA
                        </h1>
                    </div>
                </div>
                <div class='grid-item item2'>
                    <img src={FooterLogo} alt='' />
                </div>
                <div class='grid-item item3'>
                    <div className='divInsideItem'>
                        <div className='buttonsFooter'>
                            <Link to='https://www.google.com/maps/place/N%C3%A1mestie+gener%C3%A1la+%C5%A0tef%C3%A1nika+528%2F3,+064+01+Star%C3%A1+%C4%BDubov%C5%88a/@49.2995312,20.6815849,17z/data=!3m1!4b1!4m6!3m5!1s0x473e12a889474967:0x6d4006d37e99946a!8m2!3d49.2995278!4d20.6864558!16s%2Fg%2F11c26qr1mb?entry=ttu' target='_blank'>
                                <button className='offerBtn' data-hover='hover'>
                                    <span>Príďte nás navštíviť!</span>
                                    <span className='svgSpan'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='13'
                                            height='13'
                                            viewBox='0 0 13 13'
                                            fill='none'
                                        >
                                            <path
                                                d='M9.84404 12.9878C10.3896 13.0739 10.9016 12.7015 10.9878 12.156L12.3914 3.2661C12.4776 2.72057 12.1052 2.20851 11.5596 2.12237C11.0141 2.03623 10.502 2.40864 10.4159 2.95417L9.1682 10.8563L1.2661 9.60857C0.720569 9.52244 0.208505 9.89485 0.122369 10.4404C0.0362336 10.9859 0.408643 11.498 0.95417 11.5841L9.84404 12.9878ZM1.19126 1.58817L9.19126 12.5882L10.8087 11.4118L2.80874 0.411828L1.19126 1.58817Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </Link>
                        </div>
                        <h1>Námestie Generála Štefánika 3,<br></br> Stará Ľubovňa</h1>
                    </div>
                </div>
                <div class='grid-item item4'>
                    <div className='divInsideItem'>
                        <div className='buttonsFooter'>
                            <Link to='https://www.facebook.com/mariannanovasro' target='_blank'>
                                <button className='offerBtn' data-hover='hover'>
                                    <span className='svgSpan'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='13'
                                            height='14'
                                            viewBox='0 0 13 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M2.01232 12.1603C2.09872 12.7057 2.61096 13.0779 3.15644 12.9915L12.0456 11.5836C12.5911 11.4972 12.9633 10.985 12.8769 10.4395C12.7905 9.89399 12.2783 9.52183 11.7328 9.60822L3.83126 10.8597L2.57979 2.95819C2.49339 2.41271 1.98115 2.04054 1.43566 2.12694C0.890178 2.21333 0.518013 2.72557 0.604409 3.27106L2.01232 12.1603ZM10.1857 0.412215L2.19099 11.416L3.80902 12.5916L11.8038 1.58779L10.1857 0.412215Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </span>
                                    <span>INSTAGRAM</span>
                                </button>
                            </Link>
                        </div>
                        <h1>@cukraren_mariannanova</h1>
                    </div>
                </div>
                <div class='grid-item item5'>
                    <div className='divInsideItem'>
                        <div className='buttonsFooter'>
                            <button className='offerBtn' data-hover='hover'>
                                <span>Máte na nás otázku? Porozprávajme sa!</span>{' '}
                                <span className='svgSpan'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='13'
                                        height='13'
                                        viewBox='0 0 13 13'
                                        fill='none'
                                    >
                                        <path
                                            d='M9.84404 12.9878C10.3896 13.0739 10.9016 12.7015 10.9878 12.156L12.3914 3.2661C12.4776 2.72057 12.1052 2.20851 11.5596 2.12237C11.0141 2.03623 10.502 2.40864 10.4159 2.95417L9.1682 10.8563L1.2661 9.60857C0.720569 9.52244 0.208505 9.89485 0.122369 10.4404C0.0362336 10.9859 0.408643 11.498 0.95417 11.5841L9.84404 12.9878ZM1.19126 1.58817L9.19126 12.5882L10.8087 11.4118L2.80874 0.411828L1.19126 1.58817Z'
                                            fill='#432E2E'
                                        />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <h1>
                            0908 974 515
                            <br />
                            objednavky.marianna@slnet.sk
                        </h1>
                    </div>
                </div>
                <div class='grid-item item6'>
                    <h1>Privacy Policy</h1>
                </div>
                <div class='grid-item item7'>
                    <h1>By Libertas Agency</h1>
                </div>
            </div>
        </footer>
    )
}

export default Footer
