import React, { useEffect, useState, useRef } from 'react'
import './Menu.css'

import Navbar from '../../components/Navbar/Navbar'
import FirstSectionMenu from '../../components/FirstSectionMenu/FirstSectionMenu'
import RedDesignSecondMenu from '../../components/RedDesignSecondMenu/RedDesignSecondMenu'
import Footer from '../../components/Footer/Footer'
import Chlieb from '../../components/MenuLists/Chlieb/Chlieb'
import Rozok from '../../components/MenuLists/Rozok/Rozok'
import Sladke from '../../components/MenuLists/Sladke/Sladke'

const Menu = () => {
    const [state, setState] = useState('rozok')
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const resizedRef = useRef(false)

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth)
            resizedRef.current = true
        }

        window.addEventListener('resize', handleResize)

        const chlieb = document.querySelector('.menuListChlieb')
        const rozok = document.querySelector('.menuListRozok')
        const rozokX = document.querySelector('.rozok').getBoundingClientRect()
        const chliebX = document.querySelector('.chlieb').getBoundingClientRect()
        const sladkeX = document.querySelector('.sladke').getBoundingClientRect()
        const sladke = document.querySelector('.menuListSladke')
        const select = document.querySelector('.select')

        if (!resizedRef.current) {
            select.style.top = '25px'
        }

        switch (state) {
            case 'rozok':
                setTimeout(() => {
                    select.style.left = rozokX.left + rozokX.width / 2 + 'px'
                    if (!resizedRef.current) {
                        select.style.top = '0px'
                    }
                    resizedRef.current = false
                }, 300)

                rozok.style.opacity = '1'
                chlieb.style.opacity = '0'
                sladke.style.opacity = '0'
                break
            case 'chlieb':
                setTimeout(() => {
                    select.style.left = chliebX.left + chliebX.width / 2 + 'px'
                    if (!resizedRef.current) {
                        select.style.top = '0px'
                    }
                    resizedRef.current = false
                }, 300)

                chlieb.style.opacity = '1'
                sladke.style.opacity = '0'
                rozok.style.opacity = '0'
                break
            case 'sladke':
                setTimeout(() => {
                    select.style.left = sladkeX.left + sladkeX.width / 2 + 'px'
                    if (!resizedRef.current) {
                        select.style.top = '0px'
                    }
                    resizedRef.current = false
                }, 300)

                sladke.style.opacity = '1'
                chlieb.style.opacity = '0'
                rozok.style.opacity = '0'
                break
        }
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [state, windowSize])

    return (
        <>
            <Navbar />
            <FirstSectionMenu />
            <RedDesignSecondMenu state={state} setState={setState} />
            <div className='menuWrapper' id='menuWrapper'>
                <Chlieb />
                <Rozok />
                <Sladke />
                <div className='select'></div>
            </div>
            <Footer />
        </>
    )
}

export default Menu
