import React, { useState } from 'react';
import './Kolace.css'

import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'

import kol1 from '../../../Images/kolace/kol1.JPG'
import kol2 from '../../../Images/kolace/kol2.JPG'
import kol3 from '../../../Images/kolace/kol3.JPG'
import kol4 from '../../../Images/kolace/kol4.JPG'
import kol5 from '../../../Images/kolace/kol5.JPG'
import kol6 from '../../../Images/kolace/kol6.JPG'
import kol7 from '../../../Images/kolace/kol7.JPG'
import kol8 from '../../../Images/kolace/kol8.JPG'
import kol9 from '../../../Images/kolace/kol9.JPG'
import kol10 from '../../../Images/kolace/kol10.JPG'
import kol11 from '../../../Images/kolace/kol11.JPG'
import kol12 from '../../../Images/kolace/kol12.JPG'
import kol13 from '../../../Images/kolace/kol13.JPG'
import kol14 from '../../../Images/kolace/kol14.JPG'
import kol15 from '../../../Images/kolace/kol15.JPG'


const Kolace = () => {

  return (
    

      <div className='galleryBorder KolaceAllDiv'>
        <SlideshowLightbox className='containerKolace'>
          <img className='picture' src={kol1} />
          <img className='picture' src={kol2} />  
          <img className='picture' src={kol3} />
          <img className='picture' src={kol4} />
          <img className='picture' src={kol5} />  
          <img className='picture' src={kol6} />
          <img className='picture' src={kol7} />
          <img className='picture' src={kol8} />  
          <img className='picture' src={kol9} />
          <img className='picture' src={kol10} />
          <img className='picture' src={kol11} />  
          <img className='picture' src={kol12} />
          <img className='picture' src={kol13} />
          <img className='picture' src={kol14} />  
          <img className='picture' src={kol15} />
   
        </SlideshowLightbox>
      </div>
  );
}

export default Kolace
