import React from 'react'
import './FirstSectionAbout.css'

import LoadedImage from '../LoadedImage/LoadedImage'
import firstSectionAboutBG from '../../Images/aboutImage2.jpg'
import firstSectionAboutBG_blur from '../../Images/aboutImage2Blur.jpg'

const FirstSectionAbout = () => {
    return (
        <section className='firstSectionAbout' id='introduction'>
            <LoadedImage src={firstSectionAboutBG} blur={firstSectionAboutBG_blur} />
            <div className='mainContentAbout'>
                <h1>O firme</h1>
            </div>
        </section>
    )
}

export default FirstSectionAbout
