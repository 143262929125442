import { useRef } from 'react'
import { useInView } from 'framer-motion'

const ScrollAnimation = ({ children, style, classname }) => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    let styling

    if (style === 'toRight') {
        styling = {
            transform: isInView ? 'none' : 'translateX(-100px)',
            opacity: isInView ? 1 : 0,
            transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s'
        }
    }
    if (style === 'toLeft') {
        styling = {
            transform: isInView ? 'none' : 'translateX(100px)',
            opacity: isInView ? 1 : 0,
            transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s'
        }
    }
    if (style === 'up') {
        styling = {
            transform: isInView ? 'none' : 'translateY(100px)',
            opacity: isInView ? 1 : 0,
            transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s'
        }
    }
    if (style === 'upOneDelay') {
        styling = {
            transform: isInView ? 'none' : 'translateY(100px)',
            opacity: isInView ? 1 : 0,
            transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s'
        }
    }
    if (style === 'upTwoDelay') {
        styling = {
            transform: isInView ? 'none' : 'translateY(100px)',
            opacity: isInView ? 1 : 0,
            transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 1.25s'
        }
    }

    return (
        <div className={classname} ref={ref} style={styling}>
            {children}
        </div>
    )
}

export default ScrollAnimation
