import React, { useEffect, useState, useRef } from 'react'
import './CukrarenPonuka.css'

import Navbar from '../../components/Navbar/Navbar'
import FirstSectionCukrarenPonuka from '../../components/FirstSectionCukrarenPonuka/FirstSectionCukrarenPonuka'
import RedDesignSecondCukraren from '../../components/RedDesignSecondCukraren/RedDesignSecondCukraren'
import Footer from '../../components/Footer/Footer'
import Vyroba from '../../components/CukrarenList/Vyroba/Vyroba'
import Sluzby from '../../components/CukrarenList/Sluzby/Sluzby'

const CukrarenPonuka = () => {
    const [state, setState] = useState('vyroba')
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const resizedRef = useRef(false)

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth)
            resizedRef.current = true
        }

        window.addEventListener('resize', handleResize)

        const vyroba = document.querySelector('.menuListVyroba')
        const vyrobaX = document.querySelector('.vyroba').getBoundingClientRect()
        const sluzbyX = document.querySelector('.sluzby').getBoundingClientRect()
        const sluzby = document.querySelector('.menuListSluzby')
        const select = document.querySelector('.select')

        if (!resizedRef.current) {
            select.style.top = '25px'
        }

        switch (state) {
            case 'vyroba':
                setTimeout(() => {
                    select.style.left = vyrobaX.left + vyrobaX.width / 2 + 'px'
                    if (!resizedRef.current) {
                        select.style.top = '0px'
                    }
                    resizedRef.current = false
                }, 300)

                vyroba.style.display = 'flex'
                sluzby.style.display = 'none'
                vyroba.style.opacity = '1'
                sluzby.style.display = '0'
                break
            case 'sluzby':
                setTimeout(() => {
                    select.style.left = sluzbyX.left + sluzbyX.width / 2 + 'px'
                    if (!resizedRef.current) {
                        select.style.top = '0px'
                    }
                    resizedRef.current = false
                }, 300)

                sluzby.style.display = 'flex'
                vyroba.style.display = 'none'
                vyroba.style.opacity = '0'
                sluzby.style.opacity = '1'
                break
        }
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [state, windowSize])

    return (
        <>
            <Navbar />
            <FirstSectionCukrarenPonuka />
            <RedDesignSecondCukraren state={state} setState={setState} />
            <div className='menuWrapperCukraren' id='menuWrapper'>
                <Vyroba />
                <Sluzby />
                <div className='select'></div>
            </div>
            <Footer />
        </>
    )
}

export default CukrarenPonuka
