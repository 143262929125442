import React, {useEffect, useState, useRef} from 'react'
import './Gallery.css'

import Navbar from '../../components/Navbar/Navbar'
import FirstSectionGallery from '../../components/FirstSectionGallery/FirstSectionGallery'
import RedDesignSecondGallery from '../../components/RedDesignSecondGallery/RedDesignSecondGallery'
import Torty from '../../components/GalleryList/Torty/Torty'
import Kolace from '../../components/GalleryList/Kolace/Kolace'
import Footer from '../../components/Footer/Footer'

const Gallery = () => {


    const [state, setState] = useState('torta')
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const resizedRef = useRef(false)

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth)
            resizedRef.current = true
        }

        window.addEventListener('resize', handleResize)

        const vyroba = document.querySelector('.KolaceAllDiv')
        const vyrobaX = document.querySelector('.zakusok').getBoundingClientRect()
        const sluzbyX = document.querySelector('.torta').getBoundingClientRect()
        const sluzby = document.querySelector('.TortyAllDiv')
        const select = document.querySelector('.select')

        if (!resizedRef.current) {
            select.style.top = '25px'
        }

        switch (state) {
            case 'zakusok':
                setTimeout(() => {
                    select.style.left = vyrobaX.left + vyrobaX.width / 2 + 'px'
                    if (!resizedRef.current) {
                        select.style.top = '0px'
                    }
                    resizedRef.current = false
                }, 300)

                vyroba.style.display = 'flex'
                sluzby.style.display = 'none'
                vyroba.style.opacity = '1'
                sluzby.style.display = '0'
                break
            case 'torta':
                setTimeout(() => {
                    select.style.left = sluzbyX.left + sluzbyX.width / 2 + 'px'
                    if (!resizedRef.current) {
                        select.style.top = '0px'
                    }
                    resizedRef.current = false
                }, 300)

                sluzby.style.display = 'flex'
                vyroba.style.display = 'none'
                vyroba.style.opacity = '0'
                sluzby.style.opacity = '1'
                break
        }
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [state, windowSize])




    return (
        <>
            <Navbar></Navbar>
            <FirstSectionGallery></FirstSectionGallery>
            <RedDesignSecondGallery state={state} setState={setState}></RedDesignSecondGallery>
            <div className='menuWrapperGallery' id='menuWrapperGallery'>
                <Kolace></Kolace>
                <Torty></Torty>
                <div className='select'></div>
            </div>
            
            <Footer></Footer>
        </>
    )
}

export default Gallery
