import React from 'react'
import './RedDesignSecondGallery.css'

const RedDesignSecondGallery = ({ setState, state }) => {
    return (
        <div className='redDesignSecondMenu' id='offices'>
            <div className='redContentSecondMenu'>
                <div className='boxWithTextMenu'>
                    <h1>Ponuka</h1>
                    <div className='categories'>
                        <p
                            className={`zakusok ${state === 'zakusok' ? 'active' : ''}`}
                            onClick={() => setState('zakusok')}
                            data-hover='hover'
                        >
                            Zákusky
                        </p>
                        <p
                            className={`torta ${state === 'torta' ? 'active' : ''}`}
                            onClick={() => setState('torta')}
                            data-hover='hover'
                        >
                            Torty
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedDesignSecondGallery
