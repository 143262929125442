import React from 'react'
import './RedDesignSecondMenu.css'

const RedDesignSecondMenu = ({ setState, state }) => {
    return (
        <div className='redDesignSecondMenu' id='offices'>
            <div className='redContentSecondMenu'>
                <div className='boxWithTextMenu'>
                    <h1>Ponuka</h1>
                    <div className='categories'>
                        <p
                            className={`chlieb ${state === 'chlieb' ? 'active' : ''}`}
                            onClick={() => setState('chlieb')}
                            data-hover='hover'
                        >
                            Chlieb
                        </p>
                        <p
                            className={`rozok ${state === 'rozok' ? 'active' : ''}`}
                            onClick={() => setState('rozok')}
                            data-hover='hover'
                        >
                            Rožok
                        </p>
                        <p
                            className={`sladke ${state === 'sladke' ? 'active' : ''}`}
                            onClick={() => setState('sladke')}
                            data-hover='hover'
                        >
                            Sladké
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedDesignSecondMenu
