import React from 'react'
import './Rozok.css'
import croissant from '../../../Images/croissant.png'

const Rozok = () => {
    return (
        <div className='menuListRozok'>
            <div className='grid-itemMenu itemMenu1'>
                <h2>Každý deň</h2>
                <div>
                    <h3>Šatôčka:</h3>
                    <p>tvaroh, jablko, vanilka</p>
                    <h3>Hrebeň:</h3>
                    <p>lekvár, orech, mak</p>
                    <h3>Závin:</h3>
                    <p>orech, mak, kakao, dvojfarebný</p>
                    <h3>Švajčiarka</h3>
                    <h3>tlačený koláč</h3>
                    <h3>štedrák</h3>
                    <h3>jablkový košíček</h3>
                </div>
            </div>
            <div className='grid-itemMenu itemMenu2'>
                <h2>Pondelok</h2>
                <div>
                    <h3>Mama mia</h3>
                    <h3>Sójový</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu3'>
                <h2>Utorok</h2>
                <div>  
                    <h3>Grahamový</h3>
                    <h3>probiotický</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu4'>
                <h2>Streda</h2>
                <div>
                    <h3>Mama mia</h3>
                    <h3>Sójový</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu5'>
                <h2>Štvrtok</h2>
                <div>
                    <h3>Grahamový</h3>
                    <h3>drobnozrný</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu6'>
                <h2>Piatok</h2>
                <div>
                    <h3>Mama mia</h3>
                    <h3>Sójový</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu7'>
                <img src={croissant} alt='croissant' />
            </div>
        </div>
    )
}

export default Rozok
