import React from 'react'
import './RedDesignSecond.css'

const RedDesignSecond = (props) => {
    return (
        <div className='redDesignSecond' id='offices'>
            <div className='redContentSecond'>
                <div className='boxWithText'>
                    <h1>Naše hodiny</h1>
                    <p>
                        Naše otváracie hodiny sú starostlivo plánované, aby sme vám mohli ponúknuť každý deň tie
                        najlepšie produkty.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RedDesignSecond
