import React from 'react'
import './AboutThirdSection.css'

import firstImage from '../../Images/buchty.png'
import secondImage from '../../Images/torta5.png'
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation'

const AboutThirdSection = () => {
    return (
        <div className='allAboutThird'>
            <div className='AboutThirdSectionContainer'>
                <div className="leftSide whiteDiv"></div>
                <div className="middleDiv">
                    <div className='firstPart'>
                        <div className='pictures'>
                            <div className='imageNumberOne'>
                                <img src={firstImage} alt='' />
                            </div>
                            <div className='imageNumberTwo'>
                                <img src={secondImage} alt='' />
                            </div>
                        </div>
                        <div className='textContentAbout'>
                            <button className='offerBtn' data-hover='hover'>
                                <span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='13'
                                        height='14'
                                        viewBox='0 0 13 14'
                                        fill='none'
                                    >
                                        <path
                                            d='M2.01232 12.1603C2.09872 12.7057 2.61096 13.0779 3.15644 12.9915L12.0456 11.5836C12.5911 11.4972 12.9633 10.985 12.8769 10.4395C12.7905 9.89399 12.2783 9.52183 11.7328 9.60822L3.83126 10.8597L2.57979 2.95819C2.49339 2.41271 1.98115 2.04054 1.43566 2.12694C0.890178 2.21333 0.518013 2.72557 0.604409 3.27106L2.01232 12.1603ZM10.1857 0.412215L2.19099 11.416L3.80902 12.5916L11.8038 1.58779L10.1857 0.412215Z'
                                            fill='#432E2E'
                                        />
                                    </svg>
                                </span>
                                <span>O FIRME</span>
                            </button>
                            <h1>Základné kamene firemnej kultúry spoločnosti MARIANNA</h1>
                            <div>
                                <ScrollAnimation style='toLeft'>
                                    <p>
                                        Zameriavame sa na výrobu a predaj cukrárenských a pekárenských výrobkov. Dôkladne{' '}
                                        <span>monitorujeme kvalitu</span> počas výroby a skladovania, čím minimalizujeme riziko
                                        nekvality.
                                    </p>
                                </ScrollAnimation>
                                <ScrollAnimation style='toLeft'>
                                    <p>
                                        Poskytovanie prvotriednych služieb si vyžaduje prevzatie zodpovednosti za{' '}
                                        <span>riešenie problémov a reklamácií</span>. Naši pracovníci sa snažia efektívne riešiť
                                        sťažnosti a záväzky voči dodávateľom a štátu plníme dôsledne.
                                    </p>
                                </ScrollAnimation>
                                <ScrollAnimation style='toLeft'>
                                    <p>
                                        Naša základná stratégia spočíva v neustálej inovácii výrobkov podľa potrieb trhu,
                                        spotrebiteľov a našich zákazníkov. Ročne predstavujeme{' '}
                                        <span>množstvo nových výrobkov</span>, čo umožňuje zákazníkom nájsť si ideálny výrobok
                                        pre seba.
                                    </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className='secondPart'>
                        <p>
                            Veríme, že spojením týchto faktorov a našich hodnôt, ako je čestnosť a poctivosť, dosahujeme
                            spokojných zákazníkov. Naša solídna povesť spoločnosti MARIANNA, s.r.o. na Slovensku potvrdzuje
                            správnosť nášho firemného prístupu.
                        </p>
                    </div>
                </div>
                <div className="rightSide whiteDiv"></div>
            </div>
        </div>
    )
}

export default AboutThirdSection
