import React from 'react'

import Navbar from '../../components/Navbar/Navbar'
import FirstSection from '../../components/FirstSection/FirstSection'
import RedDesignSecond from '../../components/RedDesignSecond/RedDesignSecond'
import BranchOffices from '../../components/BranchOffices/BranchOffices'
import Footer from '../../components/Footer/Footer'

const Intro = () => {
    return (
        <>
            <Navbar></Navbar>
            <FirstSection></FirstSection>
            <RedDesignSecond></RedDesignSecond>
            <BranchOffices></BranchOffices>
            <Footer></Footer>
        </>
    )
}

export default Intro
