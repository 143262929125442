import React from 'react'
import './FirstSectionMenu.css'
import { HashLink } from 'react-router-hash-link'

import LoadedImage from '../LoadedImage/LoadedImage'
import firstSectionBG from '../../Images/ponukaHero2.jpg'
import firstSectionBG_blur from '../../Images/ponukaHero2Blur.jpg'

const FirstSectionMenu = () => {
    return (
        <section className='firstSectionMenu' id='introduction'>
            <LoadedImage src={firstSectionBG} blur={firstSectionBG_blur} />
            <div className='mainContentMenu'>
                <h2>Ponuka</h2>
                <h1>
                    MARIANNA
                </h1>
                <HashLink to='./#contact'>
                    <button data-hover='hover'>
                        KONTAKTUJTE NÁS
                        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='13' viewBox='0 0 14 13' fill='none'>
                            <path
                                d='M10.626 12.9202C11.1715 13.0064 11.6836 12.634 11.7697 12.0884L13.1734 3.19856C13.2595 2.65303 12.8871 2.14097 12.3416 2.05484C11.7961 1.9687 11.284 2.34111 11.1979 2.88664L9.95018 10.7887L2.04808 9.54104C1.50255 9.4549 0.990488 9.82731 0.904352 10.3728C0.818216 10.9184 1.19063 11.4304 1.73615 11.5166L10.626 12.9202ZM1.97325 1.52064L9.97325 12.5206L11.5907 11.3443L3.59072 0.344293L1.97325 1.52064Z'
                                fill='#432E2E'
                            />
                        </svg>
                    </button>
                </HashLink>
            </div>
        </section>
    )
}

export default FirstSectionMenu
