import './cursor.css'

import { useEffect } from 'react'

const Cursor = () => {
    useEffect(() => {
        const mouseMove = (e) => {
            const innerCursors = document.querySelectorAll('.innerCursor')

            const posX = e.clientX
            const posY = e.clientY

            for (let index = 0; index < innerCursors.length; index++) {
                innerCursors[index].animate(
                    {
                        left: posX + 'px',
                        top: posY + 'px'
                    },
                    {
                        duration: 500*index,
                        fill: 'forwards'
                    }
                )
            }

        }
        window.addEventListener('mousemove', mouseMove)

        return () => {
            window.removeEventListener('mousemove', mouseMove)
        }
    }, [])

    return (
        <>
            {/* <div className='outerCursor'></div> */}
            <div className='innerCursor'></div>
            {/* <div className='innerCursor'></div>
            <div className='innerCursor'></div> */}
        </>
    )
}

export default Cursor
