import React from 'react'
import './Sluzby.css'


const Sladke = () => {
    return (
        <div className='menuListSluzby'>
            <div className='grid-itemMenu itemMenu1'>
                <h2>Balenie výslužiek:</h2>
                <div>
                    <p>20 ks, 25ks, 30 ks, 35 ks, 40 ks, 50 ks, 60 ks, 70 ks, 100 ks</p>
                    <p>možnosť výberu balenia zákuskov: 20/80, 0/100 (trvanlivé/rezy)</p>
                </div>
            </div>
            <div className='grid-itemMenu itemMenu2'>
                <h2>Dovoz na miesto chladiarenským autom</h2>
                <div>
                   
                </div>
            </div>

            <div className='grid-itemMenu itemMenu3'>
                <h2>Poradenstvo pri objednávaní, výbere a množstve výrobkov</h2>
                <div>
                    
                </div>
            </div>

        </div>
    )
}

export default Sladke
