import React from 'react'

import Navbar from '../../components/Navbar/Navbar'
import FirstSectionAbout from '../../components/FirstSectionAbout/FirstSectionAbout'
import RedDesignSecondAbout from '../../components/RedDesignSecondAbout/RedDesignSecondAbout'
import AboutSecondSection from '../../components/AboutSecondSection/AboutSecondSection'
import AboutThirdSection from '../../components/AboutThirdSection/AboutThirdSection'
import Footer from '../../components/Footer/Footer'

const About = () => {
    return (
        <>
            <Navbar></Navbar>
            <FirstSectionAbout></FirstSectionAbout>
            <RedDesignSecondAbout></RedDesignSecondAbout>
            <AboutSecondSection />
            <AboutThirdSection />
            <Footer></Footer>
        </>
    )
}

export default About
