import { createRoot } from 'react-dom/client'

import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'

const container = document.getElementById('result')
const root = createRoot(container)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)
