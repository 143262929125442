import React from 'react'
import { useState } from 'react'

const LoadedImage = (props) => {
    const [loaded, setLoaded] = useState(false)

    const HandleLoad = () => {
        setLoaded(true)
    }

    const divStyle = loaded ? {} : { backgroundImage: `url(${props.blur})` }

    return (
        <div className='blurLoad' style={divStyle}  >
            <img src={props.src} alt='' className={`${loaded ? 'loaded' : ''}`} onLoad={HandleLoad} />
        </div>
    )
}

export default LoadedImage
