import React from 'react'
import './RedDesignSecondAbout.css'

const RedDesignSecondAbout = (props) => {
    return (
        <div className='redDesignSecondAbout' id='offices'>
            <div className='redContentSecond'>
                <div className='boxWithTextAbout'>
                    <h1>MARIANNA S.R.O.</h1>
                    <p>since 2008</p>
                </div>
            </div>
        </div>
    )
}

export default RedDesignSecondAbout
