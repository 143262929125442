import React from 'react'
import './Vyroba.css'

const Vyroba = () => {
    return (
        <div className='menuListVyroba'>
            <div>
                <div className='grid-itemMenu itemMenu1'>
                    <h2>Torty</h2>
                    <div>
                        <p>Klasické torty, mini torty, Pavlovovej tortičky, tortové rezy, cheesecake ai.</p>
                    </div>
                </div>
                <div className='grid-itemMenu itemMenu2'>
                    <h2>Šľahačkové zákusky</h2>
                    <div>
                        <p>Veterníky, svieži rez, toffi, ovocná lahôdka ai.</p>
                    </div>
                </div>

                <div className='gh3id-itemMenu itemMenu3'>
                    <h2>Rezy</h2>
                    <div>
                        <p>Dobošové, punčové, medové, makové, sanqvitové ai.</p>
                    </div>
                </div>

                <div className='grid-itemMenu itemMenu4'>
                    <h2>Trvanlivé pečivo</h2>
                    <div>
                        <p>Linecké pečivo, oriešky, grilážové rezy, šuhajdy ai.</p>
                    </div>
                </div>
            </div>

            <div>
                <div className='grid-itemMenu itemMenu5'>
                    <h2>Candy bar:</h2>
                    <div>
                        <p>Nanuky, tartaletky, mini veterníky, plnené poháriky ai.</p>
                    </div>
                </div>

                <div className='grid-itemMenu itemMenu6'>
                    <h2>Slané pečivo</h2>
                    <div>
                        <p>Tyčinky s posypom, škvarkové pagáče (pizza, slivkové, čisté)</p>
                    </div>
                </div>
                <div className='grid-itemMenu itemMenu6'>
                    <h2>Zmrzlina</h2>
                    <div>
                        <p>Ovocná, mliečna, sorbet</p>
                    </div>
                </div>
                <div className='grid-itemMenu itemMenu6'>
                    <h2>Lahôdkarenské výrobky:</h2>
                    <div>
                        <p>Obložené chlebíky, bagety, panini, žemle, majonézové šaláty, croissanty plnené</p>
                    </div>
                </div>
            </div>

            <div>
                <div className='grid-itemMenu itemMenu6'>
                    <h2>Bezlepkové zákusky a lahôdkarenské výrobky</h2>
                    <div>
                        
                    </div>
                </div>
                <div className='grid-itemMenu itemMenu6'>
                    <h2>Pekárske výrobky</h2>
                    <div>
                        <p>Chleby, rožky, slané a sladké pečivo, bezlepkové pekárske výrobky ai.</p>
                    </div>
                </div>
                <div className='grid-itemMenu itemMenu6'>
                    <h2>Ostatné</h2>
                    <div>
                        <p>Rolády, špice, laskonky, venčeky, Bratislavké rožteky ai.</p>
                    </div>
                </div>
            </div>
           

            
            

        </div>
    )
}

export default Vyroba
