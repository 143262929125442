import React from 'react'
import './RedDesignSecondCukraren.css'

const RedDesignSecondMenu = ({ setState, state }) => {
    return (
        <div className='redDesignSecondMenu' id='offices'>
            <div className='redContentSecondMenu'>
                <div className='boxWithTextMenu'>
                    <h1>Ponuka</h1>
                    <div className='categories'>
                        <p
                            className={`vyroba ${state === 'vyroba' ? 'active' : ''}`}
                            onClick={() => setState('vyroba')}
                            data-hover='hover'
                        >
                            Vyroba
                        </p>
                        <p
                            className={`sluzby ${state === 'sluzby' ? 'active' : ''}`}
                            onClick={() => setState('sluzby')}
                            data-hover='hover'
                        >
                            Služby
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedDesignSecondMenu
