import React, { useState } from 'react';
import './Torty.css'

import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'

import tor1 from '../../../Images/torty/tor1.JPG'
import tor2 from '../../../Images/torty/tor2.JPG'
import tor3 from '../../../Images/torty/tor3.JPG'
import tor4 from '../../../Images/torty/tor4.JPG'
import tor5 from '../../../Images/torty/tor5.JPG'
import tor6 from '../../../Images/torty/tor6.JPG'
import tor7 from '../../../Images/torty/tor7.JPG'
import tor8 from '../../../Images/torty/tor8.JPG'
import tor9 from '../../../Images/torty/tor9.JPG'
import tor10 from '../../../Images/torty/tor10.JPG'
import tor11 from '../../../Images/torty/tor11.JPG'
import tor12 from '../../../Images/torty/tor12.JPG'
import tor13 from '../../../Images/torty/tor13.JPG'
import tor14 from '../../../Images/torty/tor14.JPG'
import tor15 from '../../../Images/torty/tor15.JPG'
import tor16 from '../../../Images/torty/tor16.JPG'
import tor17 from '../../../Images/torty/tor17.JPG'
import tor18 from '../../../Images/torty/tor18.JPG'
import tor19 from '../../../Images/torty/tor19.JPG'
import tor20 from '../../../Images/torty/tor20.JPG'
import tor21 from '../../../Images/torty/tor21.JPG'
import tor22 from '../../../Images/torty/tor22.JPG'
import tor23 from '../../../Images/torty/tor23.JPG'
import tor24 from '../../../Images/torty/tor24.JPG'
import tor25 from '../../../Images/torty/tor25.JPG'



const torty = () => {

  return (
    

      <div className='galleryBorder TortyAllDiv'>
        <SlideshowLightbox className='containerKolace'>
          <img className='picture' src={tor17} />
          <img className='picture' src={tor2} />  
          <img className='picture' src={tor12} />
          <img className='picture' src={tor8} />
          <img className='picture' src={tor6} />  
          <img className='picture' src={tor14} />
          <img className='picture' src={tor7} />
          <img className='picture' src={tor18} />  
          <img className='picture' src={tor20} />
          <img className='picture' src={tor11} />
          <img className='picture' src={tor22} />  
          <img className='picture' src={tor9} />
          <img className='picture' src={tor13} />
          <img className='picture' src={tor3} />  
          <img className='picture' src={tor16} />
          <img className='picture' src={tor4} />
          <img className='picture' src={tor15} />  
          <img className='picture' src={tor19} />
          <img className='picture' src={tor1} />
          <img className='picture' src={tor10} />  
          <img className='picture' src={tor5} />
        </SlideshowLightbox>
      </div>
  );
}

export default torty
