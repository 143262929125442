import React from 'react'
import './Chlieb.css'
import bread from '../../../Images/bread.png'

const Chlieb = () => {
    return (
        <div className='menuListChlieb'>
            <div className='grid-itemMenu itemMenu1'>
                <h2>Každý deň</h2>
                <div>
                    <h3>Šatôčka:</h3>
                    <p>tvaroh, jablko, vanilka</p>
                    <h3>Hrebeň:</h3>
                    <p>lekvár, orech, mak</p>
                    <h3>Závin:</h3>
                    <p>orech, mak, kakao, dvojfarebný</p>
                    <h3>Švajčiarka</h3>
                    <h3>tlačený koláč</h3>
                    <h3>štedrák</h3>
                    <h3>jablkový košíček</h3>
                </div>
            </div>
            <div className='grid-itemMenu itemMenu2'>
                <h2>Pondelok</h2>
                <div>
                    
                    <h3>Zemiakový</h3>
                    <h3>Kváskový-kráľovský</h3>
                    <h3>Špaldovo-proteínový</h3>
                    <h3>Drobnozrný</h3>
                </div>
            </div>

            <div className='gh3id-itemMenu itemMenu3'>
                <h2>Utorok</h2>
                <div>
                    <h3>Zemiakový</h3>
                    <h3>Kváskový-kráľovský</h3>
                    <h3>Drobnozrný</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu4'>
                <h2>Streda</h2>
                <div>
                    <h3>Zemiakový</h3>
                    <h3>Kváskový-kráľovský</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu5'>
                <h2>Štvrtok</h2>
                <div>
                    <h3>Zemiakový</h3>
                    <h3>Kváskový-kráľovský</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu6'>
                <h2>Piatok</h2>
                <div>
                    <h3>Zemiakový</h3>
                    <h3>Kváskový-kráľovský</h3>
                    <h3>Kváskový Jadran</h3>
                    <h3>Dlháň</h3>
                </div>
            </div>

            <div className='grid-itemMenu itemMenu7'>
                <img src={bread} alt='croissant' />
            </div>
        </div>
    )
}

export default Chlieb
