import React from 'react'
import { HashLink } from 'react-router-hash-link'

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

import logo from '../../Images/logo.png'

const Navbar = () => {
    const [menu, setMenu] = useState(false)

    useEffect(() => {

        


        const handleScroll = () => {
            const scroll = window.scrollY
            const targetOffset = document.querySelector('#offices').offsetTop
            
            if (scroll > 100) {
                document.querySelector('.navLogo').style.opacity = 0
            } else {
                document.querySelector('.navLogo').style.opacity = 1
            }
            if ( scroll < 500) {
                document.querySelector('.blurLoad').style.top = (window.scrollY / 10).toString()  +"%"
                
            }

            
            
            if (scroll > targetOffset + 200) {
                document.querySelector('#menu').classList.add('scrolled')
            } else {
                document.querySelector('#menu').classList.remove('scrolled')
            }
        }

        window.addEventListener('scroll', handleScroll)
        try {
            if (menu !== false) {
                const mobileMenu = document.querySelector('.mobileWrapper')
                const bottomNav = document.querySelector('.bottomNav')
                mobileMenu.classList.add('active')
                bottomNav.classList.add('active')

                // const mobileNav = document.querySelector(".mobileNav");
                // mobileNav.style.zIndex = "9999999"

                const navLogo = document.querySelector('.navLogo')

                navLogo.classList.add('active')

                document.querySelector('#menu').classList.add('clicked')
            } else {
                const mobileMenu = document.querySelector('.mobileWrapper')
                const bottomNav = document.querySelector('.bottomNav')

                const navLogo = document.querySelector('.navLogo')

                // const mobileNav = document.querySelector(".mobileNav");
                // mobileNav.style.zIndex = "0"

                navLogo.classList.remove('active')
                bottomNav.classList.remove('active')
                mobileMenu.classList.remove('active')
                document.querySelector('#menu').classList.remove('clicked')
            }
        } catch {}

        return () => {
            // Cleanup: remove the event listener on component unmount.
            window.removeEventListener('scroll', handleScroll)
        }
    }, [menu])

    return (
        <header>
            <nav className='mobileNav' id='top'>
                <div className='bottomNav'>
                    <Link reloadDocument to='/' className='navLogo'>
                        <img id='mainLogo' src={logo} alt='' />
                    </Link>

                    <div
                        id='menuContainer'
                        onClick={() => {
                            setMenu(!menu)
                        }}
                        data-hover='hover'
                    >
                        <div id='menu' className='clicked'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className='mobileWrapper'>
                    <div onClick={() => setMenu(false)} className="grayOverlay"></div>
                    <div className='mobile'>
                        <ul>
                            <li>
                                <Link reloadDocument onClick={() => setMenu(!menu)} data-hover='hover' to='/ponuka'>
                                    <p>
                                        <span className='strike'>Pekáreň ponuka</span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='19'
                                            height='20'
                                            viewBox='0 0 19 20'
                                            fill='none'
                                        >
                                            <path
                                                d='M17.3182 1.88408C17.2542 1.05813 16.5327 0.440474 15.7068 0.504505L2.24716 1.54794C1.42121 1.61197 0.803553 2.33344 0.867583 3.15939C0.931613 3.98534 1.65308 4.60299 2.47903 4.53896L14.4431 3.61147L15.3706 15.5756C15.4347 16.4015 16.1561 17.0192 16.9821 16.9551C17.808 16.8911 18.4257 16.1696 18.3617 15.3437L17.3182 1.88408ZM3.13947 19.1215L16.9622 2.97552L14.6832 1.02451L0.860533 17.1705L3.13947 19.1215Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <Link reloadDocument onClick={() => setMenu(!menu)} data-hover='hover' to='/cukraren-ponuka'>
                                    <p>
                                        <span className='strike'>Cukráreň ponuka</span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='19'
                                            height='20'
                                            viewBox='0 0 19 20'
                                            fill='none'
                                        >
                                            <path
                                                d='M17.3182 1.88408C17.2542 1.05813 16.5327 0.440474 15.7068 0.504505L2.24716 1.54794C1.42121 1.61197 0.803553 2.33344 0.867583 3.15939C0.931613 3.98534 1.65308 4.60299 2.47903 4.53896L14.4431 3.61147L15.3706 15.5756C15.4347 16.4015 16.1561 17.0192 16.9821 16.9551C17.808 16.8911 18.4257 16.1696 18.3617 15.3437L17.3182 1.88408ZM3.13947 19.1215L16.9622 2.97552L14.6832 1.02451L0.860533 17.1705L3.13947 19.1215Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <Link reloadDocument onClick={() => setMenu(!menu)} data-hover='hover' to='/o-nas'>
                                    <p>
                                        <span className='strike'>O nás</span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='19'
                                            height='20'
                                            viewBox='0 0 19 20'
                                            fill='none'
                                        >
                                            <path
                                                d='M17.3182 1.88408C17.2542 1.05813 16.5327 0.440474 15.7068 0.504505L2.24716 1.54794C1.42121 1.61197 0.803553 2.33344 0.867583 3.15939C0.931613 3.98534 1.65308 4.60299 2.47903 4.53896L14.4431 3.61147L15.3706 15.5756C15.4347 16.4015 16.1561 17.0192 16.9821 16.9551C17.808 16.8911 18.4257 16.1696 18.3617 15.3437L17.3182 1.88408ZM3.13947 19.1215L16.9622 2.97552L14.6832 1.02451L0.860533 17.1705L3.13947 19.1215Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <HashLink onClick={() => setMenu(!menu)} data-hover='hover' to='/#offices'>
                                    <p>
                                        <span className='strike'>Hodiny a kontakt</span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='19'
                                            height='20'
                                            viewBox='0 0 19 20'
                                            fill='none'
                                        >
                                            <path
                                                d='M17.3182 1.88408C17.2542 1.05813 16.5327 0.440474 15.7068 0.504505L2.24716 1.54794C1.42121 1.61197 0.803553 2.33344 0.867583 3.15939C0.931613 3.98534 1.65308 4.60299 2.47903 4.53896L14.4431 3.61147L15.3706 15.5756C15.4347 16.4015 16.1561 17.0192 16.9821 16.9551C17.808 16.8911 18.4257 16.1696 18.3617 15.3437L17.3182 1.88408ZM3.13947 19.1215L16.9622 2.97552L14.6832 1.02451L0.860533 17.1705L3.13947 19.1215Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </p>
                                </HashLink>
                            </li>
                            <li>
                                <Link reloadDocument onClick={() => setMenu(!menu)} data-hover='hover' to='/galeria'>
                                    <p>
                                        <span className='strike'>Galéria</span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='19'
                                            height='20'
                                            viewBox='0 0 19 20'
                                            fill='none'
                                        >
                                            <path
                                                d='M17.3182 1.88408C17.2542 1.05813 16.5327 0.440474 15.7068 0.504505L2.24716 1.54794C1.42121 1.61197 0.803553 2.33344 0.867583 3.15939C0.931613 3.98534 1.65308 4.60299 2.47903 4.53896L14.4431 3.61147L15.3706 15.5756C15.4347 16.4015 16.1561 17.0192 16.9821 16.9551C17.808 16.8911 18.4257 16.1696 18.3617 15.3437L17.3182 1.88408ZM3.13947 19.1215L16.9622 2.97552L14.6832 1.02451L0.860533 17.1705L3.13947 19.1215Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <Link reloadDocument onClick={() => setMenu(!menu)} data-hover='hover' target='_blank' to='/napojovy-listok.pdf'>
                                    <p>
                                        <span className='strike'>Nápojový lístok</span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='19'
                                            height='20'
                                            viewBox='0 0 19 20'
                                            fill='none'
                                        >
                                            <path
                                                d='M17.3182 1.88408C17.2542 1.05813 16.5327 0.440474 15.7068 0.504505L2.24716 1.54794C1.42121 1.61197 0.803553 2.33344 0.867583 3.15939C0.931613 3.98534 1.65308 4.60299 2.47903 4.53896L14.4431 3.61147L15.3706 15.5756C15.4347 16.4015 16.1561 17.0192 16.9821 16.9551C17.808 16.8911 18.4257 16.1696 18.3617 15.3437L17.3182 1.88408ZM3.13947 19.1215L16.9622 2.97552L14.6832 1.02451L0.860533 17.1705L3.13947 19.1215Z'
                                                fill='#432E2E'
                                            />
                                        </svg>
                                    </p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Navbar
