import React from 'react'
import './FirstSectionGallery.css'

import LoadedImage from '../LoadedImage/LoadedImage'
import firstSectionAboutBG from '../../Images/blueBerriesGallery.jpg'
import firstSectionAboutBG_blur from '../../Images/blueBerriesGalleryBlur.jpg'

const FirstSectionGallery = () => {
    return (
        <section className='firstSectionAbout' id='introduction'>
            <LoadedImage src={firstSectionAboutBG} blur={firstSectionAboutBG_blur} />
            <div className='mainContentGallery'>
                <h1>Naša práca</h1>
            </div>
        </section>
    )
}

export default FirstSectionGallery
