import React from 'react'

import './AboutSecondSection.css'

import leftDivFoto from '../../Images/thirdPicture.png'
import rightFotoFirst from '../../Images/torta2.jpg'
import rightFotoSecond from '../../Images/torta3.jpg'
import rightFotoThird from '../../Images/torta4.jpg'

const AboutSecondSection = () => {
    return (
        <div className='allAboutSecond'>
            <div className='AboutSecondSectionContainer'>
                <div className='leftSide whiteDiv'></div>
                <div className='middleDiv'>
                    <div className='leftDiv'>
                        <div className='textCon'>
                            <p>
                                Firma MARIANNA, bola založená v roku 1993. Venujeme sa
                                <span> výrobe a predaju</span> cukrárenských a pekárenských výrobkov.
                            </p>
                            <p>
                                Máme okolo <span>300 rôznych výrobkov</span>, ktoré sa distribujú nielen v našich
                                predajniach, ale aj inde.
                            </p>
                            <p>
                                Naše sídlo zahŕňa <span>výrobný závod a predajňu</span>, kde ponúkame len výrobky, ktoré
                                sme sami vyrobili. Na výrobu používame tradičné metódy.
                            </p>
                        </div>
                        <div className='togetherImage'>
                            <div className='pictureWrapper'>
                                <img src={leftDivFoto} alt='' />
                            </div>
                            <div className='hiddenImg'>
                                <img src={rightFotoSecond} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='rightDiv'>
                        <div className='itemFoto1 gridFoto'>
                            <img src={rightFotoSecond} alt='' />
                        </div>
                        <div className='itemFoto2 gridFoto'>
                            <img src={rightFotoThird} alt='' />
                        </div>
                        <div className='itemFoto3 gridFoto'>
                            <img src={rightFotoFirst} alt='' />
                        </div>
                    </div>
                </div>
                <div className='rightSide whiteDiv'></div>
            </div>
        </div>
    )
}

export default AboutSecondSection
